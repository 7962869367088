.App {
    text-align: center;
    /* min-height: 100vh;
    position: relative; */
  }
  
  .App:before {
    /* content: ""; */
    background: url("/src/static/coat_of_arms_nepal.png") no-repeat fixed center;
    background-size: 30%;
    position: absolute;
    opacity: 0.05;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    pointer-events: none;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 8vh;
    padding: 0.35rem;
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  
  .ContentContainer>div {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    padding: 1rem;
    margin-top: 1rem;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }